<template>
  <section>
    <top-alternative-section :image="EnvioImage" :title="$t('gestion-de-envios.topAlternative.title')"
      :content="$t('gestion-de-envios.topAlternative.content')"></top-alternative-section>

    <features-section :title="$t('gestion-de-envios.features.title')" :description="$t('gestion-de-envios.features.description')" 
      :items="$t('gestion-de-envios.features.items')" ></features-section>

    <contact-section></contact-section>
  </section>
</template>

<script>
import TopAlternativeSection from "../sections/TopAlternativeSection.vue";
import EnvioImage from "../../assets/envio.jpg";
import FeaturesSection from "../sections/FeaturesSection.vue";
import ContactSection from "../sections/ContactSection.vue";

export default {
  components: {
    TopAlternativeSection,
    FeaturesSection,
    ContactSection
  },
  data() {
    return {
      EnvioImage: EnvioImage,
    };
  },
  metaInfo: {
    title: 'Gestión de envíos',
    htmlAttrs: {
      lang: 'en-US'
    },
    meta: [
      { charset: 'utf-8' },
      { name: 'description', content: 'Agiliza las operaciones al automatizar tareas como la generación de documentos de transporte, la asignación de conductores y la facturación. Integra con otros sistemas de la cadena de suministro, como el ERP (Enterprise Resource Planning) y WMS (Warehouse Management System), para asegurar un flujo de información eficiente entre todas las áreas de la empresa.' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' }
    ]
  }
};
</script>
